<template>
    <div>
        <b-container>
            <section class="admin">
                <div class="sector">
                    <h2 class="main-title">Admin panel</h2>
                    <AdminNavigation />
                    <div class="box">
                        <svg width="82px" height="70px" viewBox="-4 1 82 70" version="1.1">
                            <g id="vue-logo" stroke="transparent" fill="transparent" transform="translate(-3.000000, 2.000000)">
                            <path d="M15,0 L31,0 C31,0 33.7431643,4.8767365 35.1147464,7.31510474 L40,16 L50,0 L65,0 L40,42 L15,0 Z" id="inner">
                            </path>
                            <path d="M0,0 L40,68 L80,0 L65,0 C65,0 50.2018448,24.8609007 42.8027673,37.291351 C41.8685115,38.8609007 40,42 40,42 L15,0 L0,0 Z" id="outer">
                            </path>
                            </g>
                        </svg>
                    </div>
                </div>
            </section>
        </b-container>
    </div>
</template>

<script>
import AdminNavigation from '@/components/AdminNavigation'
export default {
  components: {
    AdminNavigation
  }
}
</script>

<style scoped>
.box {
  margin: 20px auto 0 auto;
  width: 220px;
  height: 190px;
}

svg {
  width: 100%;
  height: auto;
}

#outer, #inner {
  stroke-width: .5;
  stroke: #2FB982;
}

#outer {
  stroke-dasharray: 285.5394592285156 285.5394592285156;
  stroke-dashoffset: 285.5394592285156;
  -webkit-animation: outer 2s 1s ease forwards;
          animation: outer 2s 1s ease forwards;
}

#inner {
  stroke-dasharray: 165.98031616210938 165.98031616210938;
  stroke-dashoffset: 165.98031616210938;
  -webkit-animation: inner 2s 1s ease forwards;
          animation: inner 2s 1s ease forwards;
}

@-webkit-keyframes outer {
  50% {
    stroke-dashoffset: 0;
    stroke-width: .5;
    fill: #fff;
  }
  75%, 100% {
    stroke-dashoffset: 0;
    fill: #2FB982;
    stroke-width: 0;
  }
}

@keyframes outer {
  50% {
    stroke-dashoffset: 0;
    stroke-width: .5;
    fill: #fff;
  }
  75%, 100% {
    stroke-dashoffset: 0;
    fill: #2FB982;
    stroke-width: 0;
  }
}
@-webkit-keyframes inner {
  50% {
    stroke-dashoffset: 0;
    stroke-width: .5;
    fill: #fff;
  }
  75%, 100% {
    stroke-dashoffset: 0;
    fill: #34475F;
    stroke-width: 0;
  }
}
@keyframes inner {
  50% {
    stroke-dashoffset: 0;
    stroke-width: .5;
    fill: #fff;
  }
  75%, 100% {
    stroke-dashoffset: 0;
    fill: #34475F;
    stroke-width: 0;
  }
}
</style>
